import { starField } from "./components/starfield.js";

addEventListener('DOMContentLoaded', function() {
    const body = document.querySelector('body');

    if (!body) {
        return;
    }

    starField(body, 333, 3);
});
